.button-align {
  display: none;
}
:root {
  --w3m-accent: linear-gradient(45deg, #2583c0 30%, #8466dd 90%) !important;
  --w3m-border-radius-master: 2px;
}
@media only screen and (min-width: 800px) {
  .button-align {
    display: block;
  }
}
